// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-exhibition-js": () => import("./../../../src/pages/exhibition.js" /* webpackChunkName: "component---src-pages-exhibition-js" */),
  "component---src-pages-exhibition-labels-js": () => import("./../../../src/pages/exhibition/labels.js" /* webpackChunkName: "component---src-pages-exhibition-labels-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactions-js": () => import("./../../../src/pages/interactions.js" /* webpackChunkName: "component---src-pages-interactions-js" */),
  "component---src-templates-object-template-js": () => import("./../../../src/templates/objectTemplate.js" /* webpackChunkName: "component---src-templates-object-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

